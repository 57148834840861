$astronaut-dark: #0c0c0c;
$astronaut-main: #252424;
$astronaut-light: #252424;
$astronaut-light-2: #6e6d6b;
$astronaut-light-3: #727272;
$astronaut-light-4: #dedede;
$astronaut-light-5: #f5f2ee;

$chetwode-blue-dark: #f5f2ee;
$chetwode-blue-main: #c9c9c9;
$chetwode-blue-light: #0c0c0c;
$chetwode-blue-light-2: #b3b3b3;
$chetwode-blue-light-3: #9d9d9d;
$chetwode-blue-light-4: #f4f4f4;
$chetwode-blue-light-5: #c9c9c9;

$east-bay-light: #727272;
$east-bay-light-2: #9d9d9d;
$east-bay-light-3: #c9c9c9;
$east-bay-light-4: #dedede;
$east-bay-light-5: #f4f4f4;

$mariner-dark: #a83009;
$mariner-main: #f45a27;
$mariner-light: #fbc5b3;
$mariner-light-2: #f89473;
$mariner-light-3: #fdded4;
$mariner-light-4: #fdded4;
$mariner-light-5: #fef5f2;

$green-haze-dark: #0b6a3b;
$green-haze-main: #11aa5e;
$green-haze-light: #11aa5e;
$green-haze-light-2: #40ec97;
$green-haze-light-3: #6cf0af;
$green-haze-light-4: #98f5c7;
$green-haze-light-5: #edfdf5;

$cinnabar-dark: #951232;
$cinnabar-main: #e52554;
$cinnabar-light: #e52554;
$cinnabar-light-2: #ee7190;
$cinnabar-light-3: #f292a9;
$cinnabar-light-4: #f6b3c3;
$cinnabar-light-5: #fef4f7;

$mandalay-dark: #4a3300;
$mandalay-main: #ffb70e;
$mandalay-light: #ffd470;
$mandalay-light-2: #ffe3a2;
$mandalay-light-3: #fff2d2;
$mandalay-light-4: #fff9eb;
$mandalay-light-5: #fffcf5;

$black-squeeze-dark: #d8dee1;
$black-squeeze-main: #f0f7fa;
$black-squeeze-light: #fbfaf8;
$black-squeeze-light-2: #f6fafc;
$black-squeeze-light-3: #e9e6e2;
$black-squeeze-light-4: #fcfdfe;
$black-squeeze-light-5: #fefeff;

$ebony-clay-dark: #22424b;
$ebony-clay-main: #2c3040;
$ebony-clay-light: #565966;
$ebony-clay-light-2: #80838c;
$ebony-clay-light-3: #abacb3;
$ebony-clay-light-4: #d5d6d9;
$ebony-clay-light-5: #eaeaec;

$neon-carrot-light: #ffad60;
