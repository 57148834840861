@import 'styles/abstracts/mixins';
@import 'styles/abstracts/colors';
@import 'styles/layout/navigation';

$navigation-horizontal-padding: 26px;

.navigation {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  width: $extended-navigation-width;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 100;

  @media screen and (min-width: 1024px) {
    display: flex;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: $astronaut-light-5;
    top: 0;
    left: $extended-navigation-width;
    width: calc(100vw - #{$extended-navigation-width});
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-left: $navigation-horizontal-padding;
  padding-right: $navigation-horizontal-padding;
}

.top {
  margin: 14px;
  display: flex;
}

.accountSwitcherWrapper {
  margin-top: -2px;
}

.accountSwitcher {
  height: 20px;
  width: 96px;
  background: $astronaut-light-5;
  border-radius: 5px;
  margin-top: 6px;
  margin-bottom: 6px;

  @include navigationSkeletonAnimation;
}

.ecmNumber {
  font-size: 11px;
  line-height: 20px;
  color: $chetwode-blue-main;
}

.logo {
  margin-right: 12px;
  width: 43px;
  height: 36px;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(#{$extended-navigation-width} - 2 * #{$navigation-horizontal-padding});
}

.divider {
  margin: 0 0 0 -14px;
  height: 0;
  border: none;
  border-bottom: 1px solid $chetwode-blue-dark;
  width: $extended-navigation-width;
}
