@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap');

$font-family: 'Work Sans', '-apple-system', 'blinkmacsystemfont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

.highlight1 {
  font-size: 32px;
  line-height: 40px;
  font-family: $font-family;
}

.highlight2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  font-family: $font-family;
}

.subheading1 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  font-family: $font-family;
}

.subheading2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: $font-family;
}

.caption1 {
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
  font-family: $font-family;
}

.caption2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: $font-family;
}

.caption3 {
  font-size: 13px;
  line-height: 24px;
  font-weight: 700;
  font-family: $font-family;
}

.content1 {
  font-size: 14px;
  line-height: 20px;
  font-family: $font-family;
}

.content2 {
  font-size: 13px;
  line-height: 24px;
  font-family: $font-family;
}

.content3 {
  font-size: 12px;
  line-height: 16px;
  font-family: $font-family;
}

.bodyText {
  font-size: 11px;
  line-height: 20px;
  font-family: $font-family;
}
