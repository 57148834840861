@import 'styles/abstracts/mixins';
@import 'styles/abstracts/colors';

.listItem {
  height: 20px;
  margin: 18px 0;
  color: $chetwode-blue-light;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  background-color: $astronaut-light-5;
  margin-right: 12px;

  @include navigationSkeletonAnimation;
}

.link {
  height: 20px;
  width: 114px;
  border-radius: 100px;
  background-color: $astronaut-light-5;

  @include navigationSkeletonAnimation;
}
