$font-family: 'Work Sans', '-apple-system', 'blinkmacsystemfont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

.bigTitle {
  font-size: 32px;
  line-height: 36px;
  font-family: $font-family;

  @media only screen and (min-width: 960px) {
    line-height: 50px;
  }
}

.mainSectionTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  font-family: $font-family;
}

.subheading1 {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  font-family: $font-family;
}

.subheading2 {
  font-size: 15px;
  line-height: 24px;
  font-family: $font-family;
}

.body1 {
  font-size: 16px;
  line-height: 24px;
  font-family: $font-family;
}

.body2 {
  font-size: 14px;
  line-height: 20px;
  font-family: $font-family;
}

.bodyText {
  font-size: 13px;
  line-height: 24px;
  font-family: $font-family;
}

.caption {
  font-size: 11px;
  line-height: 20px;
  font-family: $font-family;
}

.buttonText {
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  font-family: $font-family;
}

.bigButtonText {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  font-family: $font-family;
}

.smallButtonText {
  font-weight: bold;
  font-size: 11px;
  line-height: 20px;
  font-family: $font-family;
}

.smallCaps {
  font-size: 12px;
  line-height: 16px;
  font-family: $font-family;
}

.wrapper {
  > * {
    padding-bottom: 24px;
  }
}
