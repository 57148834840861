@import 'styles/abstracts/colors';

$navigation-width: 74px;
$navigation-horizontal-padding: 14px;

.navigation {
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: $navigation-width;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 100;

  @media screen and (min-width: 1024px) {
    display: flex;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;

    // color in angular app
    background-color: #fdfdfd;
    top: 0;
    left: $navigation-width;
    width: calc(100vw - #{$navigation-width});
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.logo {
  margin-top: 14px;
  margin-bottom: 14px;
  width: 43px;
  height: 36px;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(#{$navigation-width} - 2 * #{$navigation-horizontal-padding});
}

.divider {
  margin: 0 0 0 -14px;
  height: 0;
  border: none;
  border-bottom: 1px solid $chetwode-blue-dark;
  width: $navigation-width;
}

.logoWrapper {
  // prevents a small "jump" of a logo
  height: 64px;
}
