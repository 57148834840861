$brand-25: #fef5f2;
$brand-50: #fdded4;
$brand-100: #fbc5b3;
$brand-200: #faac93;
$brand-300: #f89473;
$brand-400: #f67b52;
$brand-500: #f45a27;
$brand-600: #ec440c;
$brand-700: #a83009;
$brand-800: #651d05;
$brand-900: #220a02;

$neutral-0: #fff;
$neutral-50: #f4f4f4;
$neutral-100: #dedede;
$neutral-200: #c9c9c9;
$neutral-300: #b3b3b3;
$neutral-400: #9d9d9d;
$neutral-500: #888;
$neutral-600: #727272;
$neutral-700: #474747;
$neutral-800: #262626;
$neutral-900: #0e0e0e;

$neutral-2-25: #fbfaf8;
$neutral-2-50: #f5f2ee;
$neutral-2-100: #e9e6e2;
$neutral-2-200: #dcdad6;
$neutral-2-300: #c4c2be;
$neutral-2-400: #aba9a7;
$neutral-2-500: #878583;
$neutral-2-600: #6e6d6b;
$neutral-2-700: #4a4947;
$neutral-2-800: #252424;
$neutral-2-900: #0c0c0c;

$accent-1-50: #fffcf5;
$accent-1-100: #fff9eb;
$accent-1-200: #fff2d2;
$accent-1-300: #ffe3a2;
$accent-1-400: #ffd470;
$accent-1-500: #ffb70e;
$accent-1-600: #dc9a00;
$accent-1-700: #ab7800;
$accent-1-800: #7b5600;
$accent-1-900: #4a3300;

$accent-2-50: #cad4d7;
$accent-2-100: #7f979e;
$accent-2-200: #6a868e;
$accent-2-300: #55757e;
$accent-2-400: #2a525e;
$accent-2-500: #22424b;
$accent-2-600: #1d3942;
$accent-2-700: #193138;
$accent-2-800: #15292f;
$accent-2-900: #0d191c;

$accent-3-25: #edf8fd;
$accent-3-50: #daf1fa;
$accent-3-100: #bbe5f7;
$accent-3-200: #7dcdef;
$accent-3-300: #5cc1eb;
$accent-3-400: #33b1e6;
$accent-3-500: #009de0;
$accent-3-600: #007eb3;
$accent-3-700: #005172;
$accent-3-800: #00415b;
$accent-3-900: #002a3a;

$success-25: #edfdf5;
$success-50: #c4f9df;
$success-100: #98f5c7;
$success-200: #6cf0af;
$success-300: #40ec97;
$success-400: #17e57e;
$success-500: #11aa5e;
$success-600: #0f9552;
$success-700: #0b6a3b;
$success-800: #064023;
$success-900: #02150c;

$error-25: #fef4f7;
$error-50: #fad3dd;
$error-100: #f6b3c3;
$error-200: #f292a9;
$error-300: #ee7190;
$error-400: #ea5176;
$error-500: #e52554;
$error-600: #d01946;
$error-700: #951232;
$error-800: #590b1e;
$error-900: #1e040a;

$scrim: #0009;
