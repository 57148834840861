@import 'styles/abstracts/colors';

.snackbar {
  @media only screen and (max-width: 1023px) {
    top: 30px !important;
  }

  @media only screen and (max-width: 599px) {
    left: 30px !important;
    right: 30px !important;
  }

  &--subnavigation {
    left: calc(50% + (298px / 2));
    right: unset;
  }
}

.message {
  white-space: pre-line;

  // @KBartkiewicz
  // reset styles overridden by Angular
  // styles below can be removed once entire MailroomApp is in React
  margin-bottom: 0;
  color: $astronaut-main;
}
