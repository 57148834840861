@import 'styles/abstracts/colors';

// use on elements with fixed max-height and overflow: auto;
@mixin shadowOnScroll {
  background:
    /* Shadow covers */
    linear-gradient(white 30%, rgba(white, 0)),
    linear-gradient(rgba(white, 0), white 70%) 0 100%,
    linear-gradient(rgba(black, 0.05) 30%, rgba(white, 0)),
    linear-gradient(rgba(white, 0), rgba(black, 0.1) 95%) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size:
    100% 40px,
    100% 40px,
    100% 14px,
    100% 14px;
  background-attachment: local, local, scroll, scroll;
}

@mixin skeletonAnimation {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(white, 0) 0,
      rgba(white, 0.2) 20%,
      rgba(white, 0.5) 60%,
      rgba(white, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

@mixin navigationSkeletonAnimation {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(white, 0) 0,
      rgba(white, 0.1) 20%,
      rgba(white, 0.2) 60%,
      rgba(white, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

@mixin darkSkeletonAnimation {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($astronaut-light-2, 0) 0,
      rgba($astronaut-light-2, 0.1) 20%,
      rgba($astronaut-light-2, 0.2) 60%,
      rgba($astronaut-light-2, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
