@import 'styles/abstracts/mixins';
@import 'styles/abstracts/colors';

.listItem {
  height: 20px;
  margin: 16px 0;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: $astronaut-light-5;

  @include navigationSkeletonAnimation;
}
