@import 'styles/abstracts/mixins';
@import 'styles/abstracts/lz_negroni_colors';

$opactity-gray-color: rgba($neutral-100, 0.5);

.getStartedApp {
  width: 100%;
  min-height: 100vh;
  background-color: $neutral-50;
  justify-content: flex-start;
  position: relative;

  &::after,
  &::before {
    position: absolute;
    content: '';
    height: 60vh;
    width: 60%;
  }

  &::after {
    background: url(../../../../../ecm-design/legalzoom/components/core/icons/get_started_icons/background_mask_2.svg)
      no-repeat bottom left;
    background-size: contain;
    bottom: 0;
    left: 0;
  }

  &::before {
    background: url(../../../../../ecm-design/legalzoom/components/core/icons/get_started_icons/background_mask_1.svg)
      no-repeat top right;
    background-size: contain;
    right: 0;
    top: 0;
  }
}

.container {
  z-index: 1;
}

.top {
  z-index: 1;
}

.linkPlaceholder {
  height: 24px;
}

.heading {
  margin-top: 48px;
  margin-bottom: 24px;
  color: $neutral-900;

  @media only screen and (max-width: 960px) {
    font-size: 24px;
    margin-top: 16px;
  }
}

.line {
  &::after {
    background-color: $neutral-100;
    bottom: 0;
    content: '';
    height: 100%;
    left: 7px;
    position: absolute;
    top: 40px;
    width: 3px;
  }
}

.navigation {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  max-width: 270px;
  height: 170px;
  border-radius: 8px;
  padding: 16px;
  margin-left: 24px;
}

.point {
  padding-top: 25px;
  z-index: 2;
}

.icon {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: $neutral-100;
  margin-right: 12px;

  @media only screen and (max-width: 960px) {
    height: 35px;
    width: 35px;
  }

  @include skeletonAnimation;
}

.text {
  height: 18px;
  width: 100%;
  max-width: 200px;
  margin-top: 10px;
  border-radius: 6px;
  background-color: $neutral-100;

  @media only screen and (max-width: 960px) {
    width: 200px;
    margin-top: 0;
    height: 20px;
    margin-bottom: 5px;
  }

  @include skeletonAnimation;
}

.description {
  height: 13px;
  width: 100%;
  max-width: 200px;
  margin-top: 10px;
  border-radius: 6px;
  background-color: $neutral-100;

  @include skeletonAnimation;
}

.mainContent {
  border-radius: 8px;
  background-color: $neutral-0;
  padding: 48px 32px 48px 64px;
  height: 100%;

  @media only screen and (max-width: 960px) {
    padding: 32px;
  }
}

.bigIcon {
  background: $neutral-100;
  border-radius: 50%;
  min-width: 107px;
  min-height: 107px;
  width: 107px;
  height: 107px;

  @media only screen and (max-width: 600px) {
    max-height: 107px;
    max-width: 107px;
  }

  @include skeletonAnimation;
}

.mainTitle {
  background-color: $neutral-100;
  max-width: 300px;
  height: 28px;
  border-radius: 6px;
  margin-bottom: 28px;

  @include skeletonAnimation;
}

.mainDescription {
  height: 13px;
  max-width: 600px;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: $neutral-100;

  @include skeletonAnimation;
}

.mobileNavigation {
  position: relative;
}

.horizontalLine {
  &::after {
    background-color: $neutral-100;
    top: 32px;
    content: '';
    width: 65%;
    left: 18%;
    position: absolute;
    height: 3px;
  }
}

.mobileNavigationText {
  margin-top: 16px;
}
